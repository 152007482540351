<template>
  <inventory-valuation />
</template>

<script>
import InventoryValuation from '@/components/pages/inventory/InventoryValuation'

export default {
  components: {
    InventoryValuation
  }
}
</script>
